import _, { find } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { mediaUrl, useRelay } from '../../context/relay';
import { FetchStatus } from '../../types/common';
import * as http from '../../utils/httpClient';

interface Balances {
  [txid: string]: number;
}

interface Berry {
  txid: string;
}

interface RunPropsSenderArb {
  address: string;
  satoshis: number;
}

interface RunPropsT {
  $jig: string;
}

interface RunPropsSender {
  $arb: RunPropsSenderArb;
  T: RunPropsT;
}

interface RunProps {
  metadata: string;
  no: number;
  owner: string;
  satoshis: number;
  sender: RunPropsSender;
}

interface RunOrderProps {
  metadata?: string;
  no?: number;
  satoshis: number;
  sender?: RunPropsSender;
}

export interface Royalty {
  address: string;
  royalty: number;
}
export interface RunCollectible {
  berry: Berry;
  location: string;
  name: string;
  origin: string;
  owner: string;
  props: RunProps;
  creator: string;
  owners: number;
  status: string; // TODO: enum
  symbol: string;
  issued: number;
}

interface RunMetaDataBerry {
  txid: string;
}

export interface RunMetaData {
  audio?: string;
  berry: RunMetaDataBerry;
  decimals: number;
  description: string;
  image?: string;
  isCollection: boolean;
  name?: string;
  nft: boolean;
  numbered: boolean;
  symbol: string;
  ticker: string;
}

interface RunMeta {
  [key: string]: RunMetaData;
}

export enum RunOrderStatus { // TODO: What other statuses?
  live = 'live',
  open = 'open',
}

export interface RunOrder {
  amount?: number;
  berry: RunMetaDataBerry | null;
  location: string;
  origin: string;
  pay_address: string;
  pay_sats: number;
  price?: number;
  props: RunOrderProps;
  seller: string;
  status: RunOrderStatus;
  time_changed: number;
  time_posted: number;
  size?: number;
  satoshis: number; // TODO: Is this sometimes not there?
  txid?: string; // TODO: Is this sometimes not there?
}

export interface AccountData {
  balances: Balances;
  collectibles: RunCollectible[];
  metadata: RunMeta;
  orders: RunOrder[];
}

export interface Mint {
  berry: string;
  create_at: string;
  description: string;
  location: string;
  name: string;
  numbered: boolean;
  origin: string;
  owners: number;
  paymail: string;
  price: number;
  total: number;
}
export interface MintsData {
  page: number;
  offset: number;
  total: string; // This should be a number but its not
  mints: Mint[];
}
export interface MintsResult {
  data: MintsData;
  code: number;
}

interface OrdersData {
  orders: RunOrder[];
  token: RunToken;
}
export interface OrdersResult {
  data: OrdersData;
  code: number;
}
export interface AccountResult {
  data: AccountData;
}

export interface MarketStats {
  floor: number;
  rank: number;
  vol_7d: number;
  vol_24h: number;
  vol_30d: number;
}

export interface MarketRank {
  origin: string;
  name: string;
  image: string;
  stats: MarketStats;
}
export interface MarketData {
  ranks: MarketRank[];
}
export interface MarketResult {
  data: MarketData;
}
export interface RunToken {
  against: string;
  audio: string;
  burned: number;
  creator: string;
  description: string;
  decimals?: number;
  icon: { berry: string };
  isEncrypted: boolean;
  issued: number;
  location: string;
  message: '';
  name: string;
  nft: boolean;
  nonce: number;
  origin: string;
  owner: string;
  owners: number;
  price: number;
  royalties: Royalty[];
  status: string; // open
  symbol: string;
  vol: number;
  whitepaper: string;
}

export interface RunOrders {
  [key: string]: RunOrder[];
}

type ContextValue = {
  account: AccountData | undefined;
  getMeta: (origin: string) => RunMetaData | undefined;
  fetchAccountStatus: FetchStatus;
  getJamBalance: () => number;
  mints: Mint[] | undefined;
  fetchAccount: () => Promise<void>;
  collection: RunCollectible[];
  imageCollection: RunCollectible[];
  setCurrentCollectible: (collectible: RunCollectible | RunToken | null) => void;
  currentCollectible: RunCollectible | RunToken | null;
  orders: RunOrders | null;
  fetchOrdersStatus: FetchStatus;
  fetchOrders: (origin?: string) => Promise<{ token: RunToken ; orders: RunOrders } | null>;
  setFetchOrdersStatus: (status: FetchStatus) => void;
  tokens: RunToken[] | null;
  audioMints: Mint[] | undefined;
  listings: RunCollectible[];
  setOrders: (orders: RunOrders | null) => void;
  setTokens: (tokens: RunToken[] | null) => void;
  fetchMarketStatus: FetchStatus;
  fetchMarket: () => void;
  marketData: MarketData | null;
};

interface Props {}

const CollectionContext = React.createContext<ContextValue | undefined>(undefined);

const CollectionProvider: React.FC<Props> = (props) => {
  const { runOwner, paymail } = useRelay();
  // const [jam, setJam] = useState<number>(0);
  const [account, setAccount] = useState<AccountData>();
  const [mints, setMints] = useState<Mint[]>();
  const [currentCollectible, setCurrentCollectible] = useState<RunCollectible | RunToken | null>(
    null
  );
  const [fetchAccountStatus, setFetchAccountStatus] = useState<FetchStatus>(FetchStatus.Idle);
  const [fetchMintsStatus, setFetchMintsStatus] = useState<FetchStatus>(FetchStatus.Idle);

  const [orders, setOrders] = useState<RunOrders | null>(null);
  const [tokens, setTokens] = useState<RunToken[] | null>(null);
  const [fetchOrdersStatus, setFetchOrdersStatus] = useState<FetchStatus>(FetchStatus.Idle);

  const [fetchMarketStatus, setFetchMarketStatus] = useState<FetchStatus>(FetchStatus.Idle);
  const [marketData, setMarketData] = useState<MarketData | null>(null);

  const fetchMarket = useCallback(async () => {
    setFetchMarketStatus(FetchStatus.Loading);

    // https://staging-backend.relayx.com/graphql
    // POST
    const query = {
      query: `query ($filter: String!, $category: String!, $search: String) {
          ranks(filter: $filter, category: $category, search: $search) {
            origin
            name
            image
            stats {
              rank
              floor
              vol_24h
              vol_7d
              vol_30d
              vol_total
            }
          }
        }`,
      variables: {
        category: 'all',
        filter: '24h',
        search: '',
      },
    };
    try {
      const { promise } = http.customFetch<MarketResult>(`https://backend.relayx.com/graphql`, {
        method: 'POST',
        body: JSON.stringify(query),
      });
      const marketResult: MarketResult = await promise;

      setMarketData(marketResult.data);
      setFetchMarketStatus(FetchStatus.Success);
    } catch (e) {
      setFetchMarketStatus(FetchStatus.Error);
    }
  }, []);

  const fetchAccount = useCallback(async () => {
    setFetchAccountStatus(FetchStatus.Loading);

    // fetch
    // https://staging-backend.relayx.com/api/user/balance2/{owner}

    try {
      const { promise } = http.customFetch<AccountResult>(`${mediaUrl}user/balance2/${runOwner}`);
      const ownerData: AccountResult = await promise;

      setAccount(ownerData.data);
      setFetchAccountStatus(FetchStatus.Success);
    } catch (e) {
      setFetchAccountStatus(FetchStatus.Error);
    }
  }, [runOwner]);

  const listings = useMemo(() => {
    // This uses yet another format
    // amount: 1
    // berry: {txid: "5dae7f3d2143bc6e5f0061c8367d2e592bf172e21dac7e429e4e18f7550d4837_o1"}
    // location: "0aa8c755e2c06b40449b956a0ecffeb59e1f8808462addc25ae5cff586f7faf1_o2"
    // origin: "5dae7f3d2143bc6e5f0061c8367d2e592bf172e21dac7e429e4e18f7550d4837_o3"
    // pay_address: "18cRse5A1pJXK1CM98bbSkkZe1cMa9efin"
    // pay_sats: 11100000
    // price: 11100000
    // props: {metadata: {no: 35}, no: 35, satoshis: 0, sender: "18bSDTn4wxTVwMyNpthTEyfwW7afeeiWbN"}
    // seller: "18bSDTn4wxTVwMyNpthTEyfwW7afeeiWbN"
    // status: "live"
    // time_changed: 1641179856
    // time_posted: 1641179856
    return (
      account?.orders.map((o) => {
        return {
          berry: o.berry,
          location: o.location,
          origin: o.origin,
          name: '',
          props: o.props,
          owner: o.seller,
          status: o.status,
        } as RunCollectible;
      }) || []
    );
  }, [account]);

  const fetchMints = useCallback(async () => {
    // https://backend.relayx.com/api/mint/list?page=1&paymail=framesjenco@relayx.io
    if (!paymail) {
      return;
    }
    setFetchMintsStatus(FetchStatus.Loading);

    try {
      const { promise } = http.customFetch<MintsResult>(
        `${mediaUrl}mint/list?page=1&paymail=${paymail}`
      );
      const mintsData: MintsResult = await promise;

      try {
        setMints(mintsData.data.mints);
        setFetchMintsStatus(FetchStatus.Success);
      } catch (e) {
        setFetchMintsStatus(FetchStatus.Error);
      }
    } catch (e) {
      setFetchMintsStatus(FetchStatus.Error);
    }
  }, [paymail]);

  const fetchOrders = useCallback(
    async (o?: string) => {
      // https://backend.relayx.com/api/market/aea229cf58aa2d61cf9c68c417462a4defcf10cd2a9f361ae7887ffc99856917_o3/orders
      setFetchOrdersStatus(FetchStatus.Loading);

      try {
        const { promise } = http.customFetch<OrdersResult>(
          `${mediaUrl}market/${o || currentCollectible?.origin}/orders`
        );
        const ordersData: OrdersResult = await promise;

        if (ordersData.data) {
          // TODO: check codes
        }
        try {
          let newOrders = Object.assign({}, orders);
          newOrders[ordersData.data.token.origin] = ordersData.data.orders;
          // setOrders(newOrders);
          // const ts = (tokens || [])
          //   .filter((t: RunToken) => t.origin !== o)
          //   .concat([ordersData.data.token]);

          // setTokens(ts);
          // setCurrentCollectible(ordersData.data.token);

          setFetchOrdersStatus(FetchStatus.Success);
          return { orders: newOrders, token: ordersData.data.token };
        } catch (e) {
          setFetchOrdersStatus(FetchStatus.Error);
          return null;
        }
      } catch (e) {
        setFetchOrdersStatus(FetchStatus.Error);
        return null;
      }
    },
    [currentCollectible, orders, setFetchOrdersStatus]
  );

  // This is to handle when berry has been sought, but not in accounts, and not in albums
  useEffect(() => {
    const fire = async () => {
      await fetchAccount();
    };
    if (runOwner && !account && fetchAccountStatus === FetchStatus.Idle) {
      fire();
    }
    const fireMints = async () => {
      await fetchMints();
    };
    if (runOwner && !mints && fetchMintsStatus === FetchStatus.Idle) {
      fireMints();
    }
  }, [
    account,
    fetchAccount,
    fetchAccountStatus,
    fetchMints,
    fetchMintsStatus,
    fetchOrders,
    fetchOrdersStatus,
    mints,
    orders,
    runOwner    
  ]);

  // Meta is ONLY for items in your collection
  const getMeta = useCallback(
    (origin: string) => {
      if (!account) {
        return;
      }
      return account.metadata[origin];
    },
    [account]
  );

  const audioMints = useMemo(() => {
    return mints?.filter((m) => {
      let mm = getMeta(m.origin);

      return mm && !!mm.audio;
    });
  }, [getMeta, mints]);

  const imageCollection = useMemo(() => {
    if (account?.collectibles?.length) {
      let collection = account?.collectibles?.concat(listings).filter(
        (c) =>
          !getMeta(c.origin || '')?.audio &&
          c?.berry?.txid !== '993f8a0b64d26224cdb0ce80a9b24ece69be01008a6fd12cea1f4194d747f829' && // exception for satoshi bop
          c.origin !== '3660eac7b2a637b9d08290fb77f55a52d5773061e88b230ca0540e08db127b6f_o1' // exception for Shua
      );

      if (!collection) {
        return [];
      }
      return _.uniqBy(collection, function (c) {
        return c?.berry?.txid; // TODO: Using berry instead of origin allows multiples in case of collections. Might need to de-dupe if you're the owner.
      });
    } else {
      return [];
    }
  }, [account, getMeta, listings]);

  const collection = useMemo(() => {
    if (account?.collectibles?.length) {
      let collection = account?.collectibles
        ?.concat(listings)
        .filter(
          (c) =>
            !!getMeta(c.origin || '')?.audio ||
            c?.berry?.txid === '993f8a0b64d26224cdb0ce80a9b24ece69be01008a6fd12cea1f4194d747f829' ||
            c?.origin === '3660eac7b2a637b9d08290fb77f55a52d5773061e88b230ca0540e08db127b6f_o1'
        );

      if (!collection) {
        return [];
      }
      let uniqueCols = _.uniqBy(collection, function (c) {
        return c.origin;
      });
      return uniqueCols.filter((c, idx, self) => {
        const meta = getMeta(c.origin);
        return (
          !!meta?.audio ||
          c?.berry?.txid === '993f8a0b64d26224cdb0ce80a9b24ece69be01008a6fd12cea1f4194d747f829' || // Exception for Satoshi Bop (pre-audio mint)
          c?.origin === '3660eac7b2a637b9d08290fb77f55a52d5773061e88b230ca0540e08db127b6f_o1' // Exception for shua
        );
      });
    } else {
      return [];
    }
  }, [account, getMeta, listings]);

  useEffect(() => {
    console.log({ origin });
    if (origin) {
      // find a collectible in the tokens array
      let collectible: RunToken | RunCollectible | undefined = find(
        tokens,
        (t) => t.origin === origin
      );

      // if its not in tokens, try collection
      if (!collectible && collection) {
        collectible = find(collection, (c) => {
          let m = getMeta(origin);
          return !!m?.image && c.origin === origin; // origin var comes from url,
        });
      }

      // if its still not found, fetch it

      if (!!collectible) {
        setCurrentCollectible(collectible);
      }
    }
  }, [collection, getMeta, setCurrentCollectible, tokens]);

  const getJamBalance = useCallback(() => {
    let balances = account?.balances;

    return balances?.hasOwnProperty(jamContractKey) ? balances[jamContractKey] : 0;
  }, [account]);

  const value = useMemo(
    () => ({
      account,
      fetchAccount,
      getMeta,
      fetchAccountStatus,
      getJamBalance,
      runOwner,
      collection: collection || [],
      mints,
      setCurrentCollectible,
      currentCollectible,
      orders,
      fetchOrdersStatus,
      fetchOrders,
      setFetchOrdersStatus,
      tokens,
      audioMints,
      listings,
      imageCollection,
      setOrders,
      setTokens,
      fetchMarket,
      fetchMarketStatus,
      marketData,
    }),
    [
      account,
      fetchAccount,
      getMeta,
      fetchAccountStatus,
      getJamBalance,
      runOwner,
      collection,
      mints,
      currentCollectible,
      orders,
      fetchOrdersStatus,
      fetchOrders,
      setFetchOrdersStatus,
      tokens,
      audioMints,
      listings,
      imageCollection,
      setOrders,
      setTokens,
      fetchMarket,
      fetchMarketStatus,
      marketData,
    ]
  );

  return (
    <>
      <CollectionContext.Provider value={value} {...props} />
    </>
  );
};

const useCollection = (): ContextValue => {
  const context = useContext(CollectionContext);
  if (context === undefined) {
    throw new Error('useCollection must be used within an CollectionProvider');
  }
  return context;
};

export { CollectionProvider, useCollection };

// UTILS

const jamContractKey = `3025b5a18d5260482d451d3e81621d4e59e89cf8debf8098c9c4f1907c0e8755_o2`;
