
import nimble from '@runonbitcoin/nimble';
// import { ft } from '@runonbitcoin/tokenkit';
import { head } from 'lodash';
import Multiselect from 'multiselect-react-dropdown';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Run from 'run-sdk';
// import { casts, forgeTx } from 'txforge';
import * as validator from 'email-validator';
import './App.css';
import { useCollection } from './context/collection';
import { berryUrl, useRelay } from './context/relay';
import logo from './logo.svg';
import { FetchStatus } from './types/common';

enum AllocationType {
  Holder = "holder",
  Issuer = "issuer",
  Paymail = "paymail",
}

enum Denomination {
  Flat ="flat",
  Percentage ="pct",
}

type Distribution = {
  allocationType: AllocationType | undefined;
  denomination: Denomination;
  number: number;
  recipients: string[];
}

const App:React.FC = () => {
  const [selectedHolderOrigins, setSelectedHolderOrigins] = useState<string[]>([])
  const [selectedIssuerOrigins, setSelectedIssuerOrigins] = useState<string[]>([])
  const [resolveStatus, setResolveStatus] = useState<FetchStatus>(FetchStatus.Idle)
  const [paymailCache, setPaymailCache] = useState<Map<string, string>>(new Map<string, string>())
  const { authenticated, paymail, authenticate } = useRelay()
  const { account, collection, getMeta, fetchAccountStatus } = useCollection()
  const [selectedSource, setSelectedSource] = useState<string | undefined>()
  const [bomb, setBomb] = useState<any[]>([])
  
  const [distributions, setDistributions] = useState<Distribution[] | undefined>(undefined)
  const [units, setUnits] = useState<number>(0)
  
  const availablePct = useMemo(() => {
    let consumedPct = distributions?.filter((d) => d?.denomination === Denomination.Percentage && d?.number > 0)?.reduce((partialSum, a) => partialSum + a.number, 0)  
    return 100 - (consumedPct || 0)
  }, [distributions])

  const availableTokens = useMemo(() => {
    let consumedPct = distributions?.filter((d) => d?.denomination === Denomination.Flat && d?.number > 0)?.reduce((partialSum, a) => partialSum + a.number, 0)  
    return account && selectedSource ? account?.balances[selectedSource] - (consumedPct || 0) : 0
  }, [account, distributions, selectedSource])

  
  const addDistribution = useCallback(() => {
    let newDistributions = [...distributions || []]
    
    newDistributions.push({
      allocationType: distributions?.some((d) => d.allocationType === AllocationType.Holder) ? distributions?.some((d) => d.allocationType === AllocationType.Issuer) ? AllocationType.Paymail : AllocationType.Issuer : AllocationType.Holder,
      denomination: distributions?.some((d) => d.denomination === Denomination.Flat) ? Denomination.Flat : Denomination.Percentage,
      number: distributions?.some((d) => d.denomination === Denomination.Flat) ? availableTokens : availablePct,
      recipients: []
    } as Distribution)

    setDistributions(newDistributions)
    
  }, [availableTokens, availablePct, distributions, setDistributions])

  
  useEffect(() => {
    if (!selectedSource && account?.balances && Object.entries(account.balances)?.length > 0) {
      setSelectedSource(Object.entries(account.balances)[0][0])
    }
  }, [account, units, selectedSource])
  
  const updateSelectedAllocationType = useCallback((e: React.ChangeEvent<HTMLSelectElement>, idx: number) => {
    let newDistributions = [...distributions || []]
    newDistributions[idx].allocationType = e.target.selectedOptions.item(0)?.value as AllocationType || AllocationType.Holder
    setDistributions(newDistributions)    
  }, [distributions])
  
  const changeDistributionValue = useCallback((e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    let newDistributions = [...distributions || []]
    newDistributions[idx].number = parseFloat(e.target.value) || 0
    setDistributions(newDistributions)
  }, [distributions])

  const updateSelectedDenomination = useCallback((e: React.ChangeEvent<HTMLSelectElement>, idx: number) => {
    let newDenomination = e.target.selectedOptions.item(0)?.value as Denomination || Denomination.Percentage
    let newDistributions = [...distributions || []]

    newDistributions[idx].denomination = newDenomination
    newDistributions[idx].number = newDenomination === Denomination.Percentage ? availablePct : availableTokens
    setDistributions(newDistributions)
  }, [availablePct, availableTokens, distributions])

  const updateSelectedSource: React.ChangeEventHandler<HTMLSelectElement> = useCallback((e) => {
    // clear any distributions
    setDistributions([])
    
    setUnits(0)
    let newVal = e.target.selectedOptions.item(0)?.value
    if (newVal) {
      setSelectedSource(newVal)
      // set to max by default
      setUnits(account?.balances[newVal] || 0)
    }
  }, [account])

  const updateUnits: React.ChangeEventHandler<HTMLInputElement> = useCallback((e) => {
    setUnits(parseInt(e.target.value) || 0)
  }, [])

  const setMaxUnits = useCallback(() => {
    if (account?.balances && selectedSource) {
      setUnits(account.balances[selectedSource])
    }
  }, [account, selectedSource])

  const multiselectIssuerOptions = useMemo(() => {
    return [{ name: 'All Jamify NFT Issuers', id: 'all-jamify-issuer'}, ...collection.map((c) => { return { name: getMeta(c.origin)?.name, id: c.origin } })]
  }, [getMeta, collection])

  const multiselectHolderOptions = useMemo(() => {
    return [{ name: 'All Jamify NFTs', id: 'all-jamify-holder'}, ...collection.map((c) => { return { name: getMeta(c.origin)?.name, id: c.origin } })]
  }, [getMeta, collection])

  const selectHolderOrigin = useCallback((selectedList: any, selectedItem: any) => {
    console.log({ selectedList, selectedItem })
  }, [])

  const removeHolderOrigin = useCallback((selectedList: any, selectedItem: any) => {
    console.log({ selectedList, selectedItem })
  }, [])
  
  const selectIssuerOrigin = useCallback((selectedList: any, selectedItem: any) => {
    console.log({ selectedList, selectedItem })
  }, [])

  const removeIssuerOrigin = useCallback((selectedList: any, selectedItem: any) => {
    console.log({ selectedList, selectedItem })
  }, [])
  
  const formvalid = useMemo(() => {
    if (distributions?.some((d) => d.denomination === Denomination.Flat)) {
      // subtract flat denominations from total distribution
    }

    // calculate the pct base don whats left
    // make sure it matches the form
    return resolveStatus !== FetchStatus.Loading && resolveStatus !== FetchStatus.Error
  }, [resolveStatus, distributions])

  const dropTheBomb = useCallback(async () => {
    console.warn('Dropping the bomb!!')

    // 1. Flat distributions
    const flatDistributions = distributions?.filter((d) => d.denomination === Denomination.Flat)

    // Create txs for flat distributions
    // We'll use these Casts in our transaction
    // const { P2PKH, OpReturn } = casts

    console.log(nimble.PrivateKey.fromRandom().toString())    // Create a run instance on testnet
    const run = new Run({
      network: 'test',
      owner: 'cTMHbJULREfbsUFsuJLMrNbJ7VrASgWeYfFF6EgJMy49ARnNed3d',
      purse: 'cQP1h2zumWrCr2zxciuNeho61QUGtQ4zBKWFauk7WEhFb8kvjRTh'
    })
   
    // const box = await ft.getJigBox(origin)
    // console.log({ box })
    
    // await run.trust(head('ed59a3fdb8df0bbca2498372ea7ad8bf68376ac46ab0e8137e21988087f319ad_o3'.split('_')))
    // run.load('ed59a3fdb8df0bbca2498372ea7ad8bf68376ac46ab0e8137e21988087f319ad_o3')

    // Figure out how much to send to each person in this distribution
    // class Distrib extends Jig {
    //   send(to: string) {
    //     this.owner = to
    //   }
    // }
    
    // new Distrib().send(address)

    run.load(selectedSource)
    console.log( { state: run } )

    // // You'll need UTXOs to fund a transaction. Use the `toUTXO` helper to turn
    // // your UTXO data into the required objects.
    // const utxo = toUTXO({
      //   txid: "",       // utxo transaction id
    //   vout: 0,       // utxo output index
    //   satoshis: 0,   // utxo amount
    //   script: ""      // utxo lock script
    // })
    let changeAddress = '' // TODO: Self
    let txs: any[] = []
    
    for (let d of flatDistributions || []) {
      
      let outputs: any[] = []
      switch (d.allocationType) {
        case AllocationType.Holder: 
        // Get selected tokens for this distribution
        
        break
        case AllocationType.Issuer:
          
        break
        case AllocationType.Paymail:
          // make sure we have recipients in cache

          let amountPerRecipient = d.number / d.recipients.length
          for (let r of d.recipients) {
            outputs.push({address: r, value: Math.floor(amountPerRecipient)})
          }
        break
      }

      // Forge a transaction
      const tx: any = {
        outputs,
        // [
          
        //   // OpReturn.lock(0, { data: ['meta', '1DBz6V6CmvjZTvfjvWpvvwuM1X7GkRmWEq', txid] })
        // ],
        change: { address: changeAddress }
      }
      
      console.log({ tx })
      txs.push(tx)
    }


    // 2. PCT distributions
    const pctDistributions = distributions?.filter((d) => d.denomination === Denomination.Percentage)
    // Create txs for pct distributions
    
    setBomb(txs)
  }, [selectedSource, distributions])

  const changePaymails = useCallback(async (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {

    // resolve recipients for the distribution
    let paymails = e.target.value.split(',')


    console.log('resolve paymails', paymails)
    let pmc: Map<string, string> = new Map()
    setResolveStatus(FetchStatus.Loading)
    for (let paymail of paymails) {
      // validate paymail address
      if (!validator.validate(paymail)) {
        return
      }
      // look it up in cache first, then network
      if (!pmc.has(paymail)) {
        // resolve the paymail
        // https://api.polynym.io/getAddress/satchmo@relayx.io
        try {
          const resp = await fetch(`https://api.polynym.io/getAddress/${paymail}`)
          const respJson = await resp.json()
          if (respJson?.address) {
            pmc.set(paymail, respJson.address)
          }
        } catch(e) {
          setResolveStatus(FetchStatus.Error)
        }
      }
    }
    setResolveStatus(FetchStatus.Success)
    console.log({pmc})
    // setPaymailCache(pmc)

    let newDistributions = []
    for (let d of distributions || []) {
      newDistributions.push({
        ...d,
        recipients: Array.from(pmc.values())
      })
    }
    setDistributions(newDistributions)
  }, [distributions])

  const renderSource = useMemo(() => {
      return account?.balances && <div className="flex flex-col my-2">
      <div className="my-2">
        <label className="font-semibold">
        Token to Distribute
      </label>

      </div>


      <select className="rounded border p-2" name="selectedSource" onChange={updateSelectedSource}>
          {account?.balances && Object.entries(account.balances).map((ft) => <option key={ft[0]} value={ft[0]}>{getMeta(ft[0])?.name}</option>)}
      </select>

      {selectedSource && <div className="rounded my-2 bg-[#222] text-[#aaa] text-xs flex justify-center items-center">
        <img alt={getMeta(selectedSource)?.name} className="rounded w-10 mr-2" src={`${berryUrl}${head(selectedSource.split('_'))}${getMeta(selectedSource)?.image}`} /><p className="text-ellipsis overflow-hidden">{selectedSource}</p>
      </div>}
      {selectedSource && <div className="relative">
        <label className="font-semibold">Total units to Drop</label>
        <input className="rounded border invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500" type="number" value={units} min={1} onChange={updateUnits} step={1} placeholder={`units to distribute (max ${account.balances[selectedSource]})`} max={selectedSource ? account.balances[selectedSource] : 0} />
        <div className="z-10 cursor-pointer bg-[#111] rounded p-1 text-xs absolute right-0 bottom-0 mr-2 mb-2" onClick={setMaxUnits}>
          Max
        </div>
      </div>}
    </div>
    
  }, [account, selectedSource, getMeta, setMaxUnits, units, updateSelectedSource, updateUnits])

  const bombsAway = useCallback(() => {
    console.log('bombs away')
  }, [])
  
  const renderDistributions = useMemo(() => {
    return distributions && distributions.length > 0 && distributions.map((d, idx) => {
      return <div key={d.allocationType} className="p-2 rounded border bg-[#222] border-[#000] mb-2">
        <div className="my-2 flex flex-col">
          <div className="font-semibold text-sm mb-2">Distribution {idx + 1}</div>
          <select id={`denomination-${idx}`} value={d.denomination} className="rounded border p-2" placeholder="Denomination Type" onChange={(e) => updateSelectedDenomination(e, idx)}>
            <option value={Denomination.Percentage}>Percent of total units</option>
            <option value={Denomination.Flat}>Flat number of tokens</option>
          </select>

          {d.denomination === Denomination.Percentage && <div className='className="my-2'>
            <input id={`pct-${idx}`} className="invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded border-b" name={`${AllocationType.Holder}_${Denomination.Percentage}`} type="number" step={0.1} value={d.number} onChange={(e) => changeDistributionValue(e, idx)} placeholder={`percentage to distribute to ${d.allocationType}s`} />
          </div>}
          {d.denomination === Denomination.Flat && <div className='className="my-2'>
            <input id={`flat-${idx}`}  className="invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 rounded border" name={`${AllocationType.Holder}_${Denomination.Flat}`} value={d.number} onChange={(e) => changeDistributionValue(e, idx)} type="number" placeholder="number of tokens to distribute to holders" />
          </div>}
        </div>
        <div className="my-2 flex flex-col">
          <label  className="font-semibold">airdrop {(d.denomination === Denomination.Percentage && selectedSource && units ? Math.floor(d.number * units / 100) : d.number).toLocaleString('en-US')} tokens to</label>
          <select id={`allocation-type-${idx}`} className="rounded border p-2" placeholder="Allocation Type" onChange={(e) => updateSelectedAllocationType(e, idx)} value={d.allocationType}>
            <option value={AllocationType.Holder}>NFT Holders</option>
            <option value={AllocationType.Paymail}>Specific Paymails</option>
            <option value={AllocationType.Issuer}>Specific Issuers</option>
          </select>

          {d.allocationType === AllocationType.Issuer && <div className='className="my-2'>
            {/* <input className="border-b" name={`${AllocationType.Holder}_origins`} type="text" placeholder="origins" />*/}
            <Multiselect
              id={`selected-issuer-origins-${idx}`} 
              options={multiselectIssuerOptions} // Options to display in the dropdown
              selectedValues={selectedIssuerOrigins} // Preselected value to persist in dropdown
              onSelect={selectIssuerOrigin} // Function will trigger on select event
              onRemove={removeIssuerOrigin} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
              placeholder={`+ Select NFTs`}
              className="rounded bg-[#111]"
            />
          </div>}
          
          {/* {d.allocationType === AllocationType.Issuer && <div className='className="my-2'>
            <input className="border-b" name={`${AllocationType.Issuer}_origins`} type="text" placeholder="token origins, comma separated list" />
          </div>} */}
          {d.allocationType === AllocationType.Paymail && <div className='className="my-2'>
            <input className="border-b" name={`${AllocationType.Paymail}_paymails`}  type="text" onChange={(e) => changePaymails(e, idx)} placeholder="paymails, comma separated list" />
          </div>}
          {d.allocationType === AllocationType.Holder && <div className='className="my-2'>
            {/* <input className="border-b" name={`${AllocationType.Holder}_origins`} type="text" placeholder="origins" />*/}
            <Multiselect
              id={`selected-holder-origins-${idx}`} 
              options={multiselectHolderOptions} // Options to display in the dropdown
              selectedValues={selectedHolderOrigins} // Preselected value to persist in dropdown
              onSelect={selectHolderOrigin} // Function will trigger on select event
              onRemove={removeHolderOrigin} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
              placeholder={`+ Select NFTs`}
              className="rounded bg-[#111]"
            />
          </div>}

        </div>
      </div>
    })
  }, [changePaymails, multiselectIssuerOptions, removeIssuerOrigin, selectIssuerOrigin, selectedIssuerOrigins, units, changeDistributionValue, updateSelectedDenomination, updateSelectedAllocationType, selectHolderOrigin, removeHolderOrigin, multiselectHolderOptions, distributions, selectedHolderOrigins, selectedSource])
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        <h1 className="text-3xl font-bold my-2">
          BombDrop.xyz
        </h1>
        
        <h2 className=" my-2 text-2xl font-semibold">
          Run Token Bomber
        </h2>
        {!authenticated && <button onClick={authenticate}>Sign In with RelayX</button>}
        {authenticated && <div className="my-4 text-base flex items-center justify-center">
          <img alt={paymail} className="rounded-lg w-8 mr-3" src={`https://bitpic.network/u/${paymail}`} />{paymail}
        </div>}
      </header>
      {bomb.length === 0  && <div className="w-full max-w-lg mx-auto p-4">
        {fetchAccountStatus === FetchStatus.Loading && "..."}
        {account && <form>
          {renderSource}
          
          <hr className="w-full h-1 bg-[#222] border-0 my-4" />

          {distributions && renderDistributions}

          <div className="flex flex-col items-center justify-center my-8">
            <button disabled={!units || units === 0} type="button" className="px-4 py-2 bg-blue-400 rounded font-semibold mb-4" onClick={addDistribution}>{distributions && distributions?.length === 0 ? 'Create' : 'Add'} Distribution</button>

            {distributions && distributions?.length > 0 && <hr className="w-full h-1 bg-[#222] border-0 my-2" />}
            {distributions && distributions?.length > 0 && <button type="button" disabled={!formvalid} className="my-4 px-4 py-2 bg-emerald-400 rounded font-semibold" onClick={dropTheBomb}>Drop the Bomb</button>}
          </div>
        </form>}
      </div>}

      {selectedSource && bomb.length > 0 && bomb.map((tx) => {
        let meta = getMeta(selectedSource)
        return <div key={tx.id}>
          <img src={`${berryUrl}${head(selectedSource.split('_'))}${meta?.image}`} className="w-12" />
          
          <h2>{meta?.name} ({meta?.symbol})</h2>
          {selectedSource ? meta?.description : ''}
          <h1>Dropping {tx.outputs.map((o: any) => o.value).reduce((partialSum: number, a: number) => partialSum + a, 0)} tokens to {tx.outputs.length} recipients</h1>
          {tx.outputs.map((o: any) => <div>Address: {o.address}, Amount: {o.value} sat</div>)}
          {tx.change?.address}<hr />
          
          <button onClick={bombsAway}>confirm</button></div>
      }, [])}

      <br />
      <br />
      
      <script async defer type="application/javascript" src="https://tonicpow.com/scripts/tonicpow.js"></script>
      <div className="tonicpow-widget" data-widget-id="24f5cee1d2e734eede15afc627e6c2fd9ea01379c6c2307dd0119fd0621c7a5c" />

    </div>
  );
}

export default App;


// Requirements
// Drop to specific NFT holders
// Drop to a list of paymails (feature funders)
// Drop to a list of NFT issuers
// Drop lists generated from list of origins